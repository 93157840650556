import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "./components/configs/ScrollToTop";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { FEATURES, LANGUAGES } from "./utils/constants";
import "./styles.css";
import { firebase } from "./firebase";
import * as api from "./api";
import PostsManagementModal from "./pages/account/PostsManagementModal/PostsManagementModal";
import Notification from "./components/utils/Notification";

// Optimized import with React.lazy for code splitting
const LazyHeader = React.lazy(() =>
  import("./components/layout/header/header")
);
const LazyFooter = React.lazy(() =>
  import("./components/layout/footer/footer")
);
const LazyTopFooter = React.lazy(() =>
  import("./components/layout/top_footer")
);
const LazyRoutes = React.lazy(() => import("./routes"));

const App = () => {
  const [notification, setNotification] = useState({ open: false });
  const [categoriSelectedAutoComplete, setCategoriSelectedAutoComplete] =
    useState("all-areas");
  const [parametroDaPesquisa, setParametroDaPesquisa] = useState("");
  const [isPesquisaNoStickyAutoComplete, setIsPesquisaNoStickyAutoComplete] =
    useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [alternateUrl, setAlternateUrl] = React.useState("");

  //popup login
  const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);
  const [isValidationLogin, setIsValidationLogin] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const [isEmailVerification, setIsEmailVerification] = useState(false);
  // posts management modal
  const [postsManagementModalState, setPostsManagementModalState] = useState({
    open: false
  });
  const [postsManagementContent, setPostsManagementContent] = useState("lists");

  const [alternateUrls, setAlternateUrls] = React.useState([
    { locale: location.pathname.split("/")[1] || "en", url: "/pt/" }
  ]);

  const [configuracao, setConfiguracao] = useState({
    corLayout: "#EAEAEA",
    sectionFooter: true,
    corFooter: "#EAEAEA"
  });

  const isTabletMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const [language, setLanguage] = useState(
    LANGUAGES[location.pathname.split("/")[1]?.toUpperCase()] || LANGUAGES.EN
  );
  const [previousLocation, setPreviousLocation] = React.useState(
    location.pathname
  );
  const [changedLanguage, setChangedLanguage] = React.useState(false);
  const [serviceSearchQuery, setServiceSearchQuery] = useState("");
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isMyAccountLoading, setIsMyAccountLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [dataGridObject, setDataGridObject] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [objects, setObjects] = useState([]);
  const [categorieSelected, setCategorieSelected] = useState("");
  const [newDesertDefault, setNewDesertDefault] = useState([]);

  const [mainStyle, setMainStyle] = useState({
    background: isTabletMobile ? configuracao.corLayout : "none"
  });

  const [isAccountPage, setIsAccountPage] = useState(
    location.pathname.includes("a-minha-conta") ||
      location.pathname.includes("my-account")
  );

  // shows bottom notification
  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        setNotification((prev) => ({ ...prev, open: false }));
        setTimeout(() => setNotification({ open: false }), 500);
      }, 5000);
    }
  }, [notification]);

  // to detect authentication status
  useEffect(() => {
    /*
    getRedirectResult(firebase.auth).then((result) => {
      const { user } = result || {}
      if (user && user.emailVerified && !isEmailVerification) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          uid: user.uid,
          email_verified: user.emailVerified,
          small_uid: user.uid.slice(0, 5) + "..."
        }));
      } else {
        setUserData(null);
        if (isEmailVerification) {
          setIsEmailVerification(false);
        }
      }
    }); */
    firebase.auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified && !isEmailVerification) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          uid: user.uid,
          email_verified: user.emailVerified,
          small_uid: user.uid.slice(0, 5) + "..."
        }));
      } else {
        setUserData(null);
        if (isEmailVerification) {
          setIsEmailVerification(false);
        }
      }
    });
  }, [location.pathname]);

  // to get user data
  useEffect(() => {
    const fetchUserData = async () => {
      const {
        data: user_data,
        get_data_success,
        get_data_error
      } = await api.users.get({
        uid: userData.uid
      });
      if (get_data_success) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          ...user_data
        }));
      } else {
        // TO DO
        // handle feedback from data error
        console.log(get_data_error);
      }
      const {
        get_lists_error,
        data: user_lists,
        get_lists_success
      } = await api.lists.get({ uid: userData.uid });
      if (get_lists_success) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          lists: user_lists
        }));
      } else {
        console.log(get_lists_error);
      }
      const {
        data: user_newsletters,
        get_user_newsletters_error,
        get_user_newsletters_success
      } = await api.newsletters.subscribed({ uid: userData.uid });
      if (get_user_newsletters_success) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          newsletters: user_newsletters
        }));
      } else {
        console.log(get_user_newsletters_error);
      }
      if (FEATURES.NOTIFICATIONS) {
        const {
          get_notifications_error,
          data: user_notifications,
          get_notifications_success
        } = await api.notifications.subscribed({ uid: userData.uid });
        if (get_notifications_success) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            notifications: user_notifications
          }));
        } else {
          console.log(get_notifications_error);
        }
      }
    };
    if (
      userData?.uid &&
      !userData.user_email &&
      userData.email_verified &&
      !isEmailVerification
    ) {
      fetchUserData();
    }
  }, [userData]);

  // use effect to detect when we move back to the homepage to reset alternate url
  useEffect(() => {
    if (location.pathname.split("/").length === 3) {
      setAlternateUrl(location.pathname === "/en/" ? "/pt/" : "/en/");
    }
    setIsAccountPage(
      location.pathname.includes("a-minha-conta") ||
        location.pathname.includes("my-account")
    );
  }, [location.pathname]);

  useEffect(() => {
    if (isTabletMobile && isAccountPage) {
      setMainStyle({ backgroundColor: "white" });
    } else {
      setMainStyle({
        background: "linear-gradient(180deg, #fff 5%, #e9e9e9 100%)"
      });
    }
  }, [isTabletMobile, isAccountPage]);

  useEffect(() => {
    if (changedLanguage) {
      setChangedLanguage(false);
      navigate(alternateUrl);
      setLoading(true);
    }
  }, [changedLanguage]);

  useEffect(() => {
    if (
      !location.pathname.includes("search") &&
      !location.pathname.includes("pesquisa") &&
      location.pathname !== previousLocation
    )
      setParametroDaPesquisa("");
    if (location.pathname === "/") {
      navigate(`/${language.locale}/`);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Fetch translations and other data based on language
    const fetchData = async () => {
      setServiceSearchQuery("");
      const url = `${
        process.env.REACT_APP_API_ENDPOINT
      }requests/main_request?language=${
        language.supported ? language.locale : "en"
      }`;

      const username = "api";
      const password = "vH5PGsT3wVjm";
      const basicAuth = "Basic " + btoa(username + ":" + password);

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: basicAuth
          }
        });

        const {
          menu,
          objects: new_objects,
          translations: new_translations
        } = response.data?.result || {};

        //console.log(new_objects);
        setTranslations(new_translations || {});
        setObjects(new_objects || []);
        setNewDesertDefault(
          new_objects?.filter((obj) => obj.object_id === "6") || []
        );
        menu.sort((a, b) => {
          if (["2"].includes(a.object_id)) {
            return -1;
          }
          if (["1"].includes(a.object_id)) {
            if (["2"].includes(b.object_id)) {
              return 1;
            }
            return -1;
          }
          if (["4"].includes(a.object_id)) {
            if (["1", "2"].includes(b.object_id)) {
              return 1;
            }
            return -1;
          }

          if (["3"].includes(a.object_id)) {
            if (["1", "2", "4"].includes(b.object_id)) {
              return 1;
            }
            return -1;
          }
          return 1;
        });
        setMenuList(menu || []);

        if (
          location.search !== "" &&
          !location.pathname.includes("account-actions")
        ) {
          navigate(location.pathname);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [language, location.search, location.pathname]);

  // Function to clear the search bar
  const clearSearchBar = () => {
    setParametroDaPesquisa("");
  };

  return (
    <div
      className={`flex flex-col`}
      style={{ background: isTabletMobile ? configuracao.corLayout : "none" }}
    >
      {!loading && (
        <React.Suspense fallback={<></>}>
          {!location.pathname.includes("account-actions") && (
            <LazyHeader
              setPage={setPage}
              objects={objects}
              firebase={firebase}
              menuList={menuList}
              userData={userData}
              language={language}
              setLanguage={setLanguage}
              setUserData={setUserData}
              translations={translations}
              alternateUrls={alternateUrls}
              setNotification={setNotification}
              isModalOpenLogin={isModalOpenLogin}
              setAlternateUrls={setAlternateUrls}
              isValidationLogin={isValidationLogin}
              setChangedLanguage={setChangedLanguage}
              isMyAccountLoading={isMyAccountLoading}
              setIsModalOpenLogin={setIsModalOpenLogin}
              parametroDaPesquisa={parametroDaPesquisa}
              setPreviousLocation={setPreviousLocation}
              setIsMyAccountLoading={setIsMyAccountLoading}
              setIsEmailVerification={setIsEmailVerification}
              setParametroDaPesquisa={setParametroDaPesquisa}
              categoriSelectedAutoComplete={categoriSelectedAutoComplete}
              isPesquisaNoStickyAutoComplete={isPesquisaNoStickyAutoComplete}
              setCategoriSelectedAutoComplete={setCategoriSelectedAutoComplete}
              setIsPesquisaNoStickyAutoComplete={
                setIsPesquisaNoStickyAutoComplete
              }
              categorieSelected={categorieSelected}
              setCategorieSelected={setCategorieSelected}
              clearSearchBar={clearSearchBar}
              isDeleteAccountModalOpen={isDeleteAccountModalOpen}
              setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen}
            />
          )}
          {!isMyAccountLoading && (
            <main
              style={mainStyle}
              className={`grid gap-4 flex-1 w-full main-container`}
            >
              {!location.pathname.includes("account-actions") &&
                ((isTabletMobile && !isAccountPage) || !isTabletMobile) && (
                  <ScrollToTop />
                )}
              <LazyRoutes
                data={data}
                page={page}
                setData={setData}
                objects={objects}
                setPage={setPage}
                userData={userData}
                language={language}
                firebase={firebase}
                setObjects={setObjects}
                setUserData={setUserData}
                translations={translations}
                configuracao={configuracao}
                alternateUrls={alternateUrls}
                isTabletMobile={isTabletMobile}
                dataGridObject={dataGridObject}
                setAlternateUrl={setAlternateUrl}
                setConfiguracao={setConfiguracao}
                setNotification={setNotification}
                newDesertDefault={newDesertDefault}
                setAlternateUrls={setAlternateUrls}
                previousLocation={previousLocation}
                categorieSelected={categorieSelected}
                setDataGridObject={setDataGridObject}
                serviceSearchQuery={serviceSearchQuery}
                isMyAccountLoading={isMyAccountLoading}
                setIsModalOpenLogin={setIsModalOpenLogin}
                setNewDesertDefault={setNewDesertDefault}
                parametroDaPesquisa={parametroDaPesquisa}
                setIsValidationLogin={setIsValidationLogin}
                setCategorieSelected={setCategorieSelected}
                setIsMyAccountLoading={setIsMyAccountLoading}
                setServiceSearchQuery={setServiceSearchQuery}
                setParametroDaPesquisa={setParametroDaPesquisa}
                setPostsManagementModalState={setPostsManagementModalState}
                categoriSelectedAutoComplete={categoriSelectedAutoComplete}
                isPesquisaNoStickyAutoComplete={isPesquisaNoStickyAutoComplete}
                setCategoriSelectedAutoComplete={
                  setCategoriSelectedAutoComplete
                }
                setIsPesquisaNoStickyAutoComplete={
                  setIsPesquisaNoStickyAutoComplete
                }
                isDeleteAccountModalOpen={isDeleteAccountModalOpen}
                setIsDeleteAccountModalOpen={setIsDeleteAccountModalOpen}
              />
              {!location.pathname.includes("account-actions") &&
                configuracao.sectionFooter &&
                !isTabletMobile && (
                  <LazyTopFooter
                    objects={objects}
                    language={language}
                    translations={translations}
                  />
                )}
            </main>
          )}
          {!isMyAccountLoading &&
            !location.pathname.includes("account-actions") &&
            ((isTabletMobile && !isAccountPage) || !isTabletMobile) && (
              <LazyFooter
                objects={objects}
                language={language}
                userData={userData}
                translations={translations}
                configuracao={configuracao}
                alternateUrls={alternateUrls}
                isTabletMobile={isTabletMobile}
                isModalOpenLogin={isModalOpenLogin}
                setAlternateUrls={setAlternateUrls}
                setIsModalOpenLogin={setIsModalOpenLogin}
              />
            )}
        </React.Suspense>
      )}
      <PostsManagementModal
        userData={userData}
        setUserData={setUserData}
        translations={translations}
        postsManagementContent={postsManagementContent}
        postsManagementModalState={postsManagementModalState}
        setPostsManagementContent={setPostsManagementContent}
        setPostsManagementModalState={setPostsManagementModalState}
      />
      <Notification notification={notification} />
    </div>
  );
};

export default App;
